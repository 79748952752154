import { Equipment } from '@libs/entity-lib';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as fromActions from './equipment.actions';

export interface State extends EntityState<Equipment> {
  // additional entities state properties
  loading: boolean;
  selectEquipmentId: number | null;
  error: any | null;
}
export function selectEquipmentId(a: Equipment): number {
  //In this case this would be optional since primary key is id
  return a.id ?? -1;
}

export const adapter: EntityAdapter<Equipment> = createEntityAdapter<Equipment>({
  selectId: selectEquipmentId,
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  loading: false,
  selectEquipmentId: null,
  error: null,
});

export const reducer = createReducer(
  initialState,
  on(fromActions.fetchEquipmentSuccefully, (state, { equipments }) => {
    return adapter.setAll(equipments, {
      ...state,
      loading: false,
      error: null,
    });
  }),
  on(fromActions.fetchEquipmentFailed, (state, { error }) => {
    return adapter.setAll([], { ...state, loading: false, error });
  }),
  on(fromActions.fetchEquipment, (state) => ({ ...state, loading: true })),
  on(fromActions.postEquipment, (state) => ({ ...state, loading: true, error: null })),
  on(fromActions.postEquipmentSuccefully, (state, { equipment }) =>
    adapter.setOne(equipment, { ...state, error: null, loading: false })
  ),
  on(fromActions.postEquipmentFailed, (state, { error }) => {
    return { ...state, loading: false, error };
  }),
  on(fromActions.putEquipment, (state) => ({ ...state, loading: true })),
  on(fromActions.putEquipmentSuccefully, (state, { equipment }) => {
    return adapter.updateOne({id: equipment.id, changes: equipment}, {...state, error: null, loading: false});
  }),
  on(fromActions.putEquipmentFailed, (state, { error }) => {
    return { ...state, loading: false, error };
  }),
  on(fromActions.setSelectedEquipment, (state, { equipmentId }) => {
    return {
      ...state,
      selectEquipmentId: equipmentId,
    };
  }),
  on(fromActions.deleteEquipmentSuccessufly, (state, { equipmentId }) =>
    adapter.removeOne(equipmentId, { ...state, selectCourtId: null })
  ),
);

export const getSelectedEquipmentId = (state: State) => state.selectEquipmentId;

// get the selectors
const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

// select the array of equipment ids
export const selectCourtIds = selectIds;

// select the dictionary of equipment entities
export const selectCourtEntities = selectEntities;

// select the array of equipments
export const selectAllCourts = selectAll;

// select the total equipment count
export const selectCourtTotal = selectTotal;
